import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sumScholarshipArray'
})
export class SumScholarshipArrayPipe implements PipeTransform {

  transform(data: Array<any>, args?: any): any {
    let value = 0
    data.map((m) => {
      if (m.scholarshipDiscountData) {
        const { amount, amountOther } = m.scholarshipDiscountData
        value += amount + amountOther
      }
    })
    return value;
  }

}
